.user-interests {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: relative;
  .graphics {
    .blue-hollow-chip-1 {
      width: 50vw;
      right: -16vw;
      transform: rotate(-5deg);
      top: 5vw;
      path {
        fill: $light-blue;
      }
    }

    .red-hollow-chip {
      width: 38vw;
      right: -3vw;
      top: 30vw;
      transform: rotate(-40deg);
      path {
        fill: $red;
      }
    }

    .yellow-hollow-chip {
      right: -3vw;
      width: 50vw;
      top: 30%;
      transform: rotate(-16deg);
      path {
        fill: $yellow;
      }
    }

    .green-hollow-chip {
      width: 50vw;
      left: -16vw;
      transform: rotate(-35deg);
      bottom: -25vw;
      path {
        fill: $green;
      }
    }

    .blue-hollow-chip-2 {
      width: 30vw;
      left: 4vw;
      transform: rotate(-5deg);
      bottom: 0vw;
      path {
        fill: $light-blue;
      }
    }

    .panel {
      position: absolute;
      width: 8vw;
      height: 40%;
      background-color: $light-blue;
      &.left-side {
        left: -6vw;
        top: -4vw;
      }
      &.right-side {
        right: 0;
        bottom: 0;
      }
    }

    .box {
      position: absolute;
      width: 20vw;
      height: 20vw;
      background-color: red;

      &.left-side {
        top: 0vw;
        left: -11vw;
        width: 15vw;
        height: 15vw;
      }
      &.right-side {
        right: -2vw;
        top: 62%;
      }
    }
  }

  .content {
    z-index: 1;
    width: 100%;
    padding: 0 5vw 50vw;
    h3 {
      font-size: 8vw;
      line-height: 1.5;
      width: 110%;
      border-bottom: 2px solid $white;
      margin-bottom: 5vw;
    }

    .items-list {
      li {
        cursor: default;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .graphics {
      svg {
        display: none;
      }
      .panel {
        &.left-side {
          width: 11vw;
          height: 110%;
          top: -2rem;
        }
        &.right-side {
          display: none;
        }
      }
      .box {
        &.left-side {
          left: 1.5rem;
          width: 8rem;
          height: 8rem;
          top: -0.5rem;
        }
        &.right-side {
          display: none;
        }
      }
    }
    .content {
      padding: 0 10vw 5rem;
      h3 {
        font-size: 3rem;
        width: 100%;
        margin-bottom: 3rem;
      }
      .items-list {
        li {
          width: auto;
          font-size: 1.8rem;
          margin-right: 3rem;
          margin-bottom: 0.5rem;
        }
        li:first-child:nth-last-child(n + 5),
        li:first-child:nth-last-child(n + 5) ~ li {
          width: 23%;
          margin-right: 2%;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .graphics {
      .panel {
        &.left-side {
          width: 14rem;
          left: -7.5rem;
          top: -3rem;
        }
      }
      .box {
        &.left-side {
          top: -0.5rem;
          left: 2rem;
          width: 8rem;
          height: 8rem;
        }
      }
    }
    .content {
      .items-list {
        li {
          font-size: 2rem;
        }
      }
    }
  }
}
