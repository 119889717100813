.challenge-submission-attachments {
  .attachments-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4vw;
    li {
      width: 20vw;
      height: 20vw;
      margin-right: 3vw;
      border: 1px solid $grey;
      position: relative;
      cursor: pointer;
      background-color: $black;

      img,
      .video-thumbnail,
      .zip-thumbnail {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: $black;
        z-index: 1;
      }

      .video-thumbnail,
      .zip-thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        svg {
          width: 40%;
          height: 40%;
          fill: $white;
        }
      }

      .zip-thumbnail {
        cursor: default;
      }

      &.uploading {
        background: $black;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .upload-progress-bar {
          position: absolute;
          width: 50%;
          background: $grey;
          height: 1rem;
          border-radius: 1rem;
          overflow: hidden;

          .upload-progress-amount {
            position: absolute;
            background: $light-blue;
            height: 100%;
          }
        }
      }

      .delete-button {
        position: absolute;
        width: 5vw;
        height: 5vw;
        top: -2.5vw;
        right: -2.5vw;
        z-index: 2;
        cursor: pointer;
        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }

  .upload-button {
    label {
      font-size: 4vw;
      line-height: 1;
      cursor: pointer;
      padding: 2vw 0;
      background-color: $neon-green;
      font-family: $opensanscondensed;
      font-weight: 700;
      display: block;
      color: $black;
      width: 30vw;
      text-align: center;
    }
  }
  @include media-breakpoint-up(lg) {
    .attachments-list {
      margin-bottom: 0;
      li {
        width: 15rem;
        height: 15rem;
        margin: 0 2rem 2rem 0;

        .delete-button {
          width: 2rem;
          height: 2rem;
          top: -1rem;
          right: -1rem;
        }
      }
    }
    .upload-button {
      margin-bottom: 1rem;
      label {
        font-size: 2.25rem;
        padding: 1rem 0;
        width: 15rem;
      }
    }
  }
}
