.search-input {
  width: 90%;
  position: relative;
  border-bottom: 3px solid #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  svg {
    width: 5vw;
    position: relative;
    left: -1rem;

    &.search-clear {
      background: transparent;
      border-radius: 5vw;
      height: 5vw;
      border: 2px solid white;
      cursor: pointer;
    }
  }
  label {
    display: none;
  }
  input {
    background: transparent;
    border-width: 0;
    color: $white;
    text-align: center;
    font-family: 'Anton';
    text-transform: uppercase;
    font-size: 5vw;
    text-overflow: ellipsis;
    text-align: left;
    width: 50%;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      color: $white;
      text-transform: uppercase;
    }
  }

  // 996 ->
  @include media-breakpoint-up(lg) {
    width: 100rem;
    margin: 0 0 3rem;

    svg {
      width: 3.75rem;
      &.search-clear {
        height: 3.75rem;
        border-radius: 3.75rem;
      }
    }

    input {
      font-size: 3.75rem;
    }
  }
}
