.featured-challenge {
  width: 100%;
  padding: 15rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $black;

  .details {
    z-index: 1;
    width: 100%;
    padding: 5rem 10%;
    position: relative;
    .border {
      width: 2rem;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0.9;

      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }

    h2 {
      filter: $dropshadow;
    }
    .description {
      display: flex;
      justify-content: center;
      p {
        filter: $dropshadow;
        max-width: 80rem;
      }
    }
  }

  .btn {
    margin-top: 5rem;
    z-index: 2;
  }

  @include media-breakpoint-up(lg) {
    .details {
      .border {
        max-width: 9rem;
        width: 5%;
      }
      .description {
        p {
          line-height: 1.5;
        }
      }
    }
  }
}
