$anton: 'Anton', sans-serif;
$opensans: 'Open Sans', serif;
$opensanscondensed: 'Open Sans Condensed', serif;

//// COLORS

$white: #fafafa;
$bright-white: #fff;
$black: #000;
$grey: #8a8a8a;
$grey-2: #404040;
$grey-3: #606060;

$red: #d90307;
$orange: #fd5e00;
$yellow: #e3d31a;
$purple: #960dfb;
$neon-green: #72fb0d;
$pink: #fd03fd;
$dark-blue: #218af4;
$light-blue: #0dfbfb;

// UI

$dropshadow: drop-shadow(0.2rem 0.2rem 0.6rem $black);

// SIZES

$navHeightMobile: 28vw;
$navHeightDesktop: 14.2rem;
