/* TYPOGRAPHY */

@font-face {
  font-family: 'Anton';
  src: url('../assets/fonts/Anton-Regular.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('../assets/fonts/OpenSansCondensed-Light.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('../assets/fonts/OpenSansCondensed-Bold.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
}

html {
  font-size: 62.5%;
}

body {
  font-family: $opensans;
  color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $anton;
  font-weight: 300;
  color: $white;
  margin-bottom: 1rem;
}

h1 {
  font-size: 18vw;
  line-height: 16.3vw;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 15vw;
    line-height: 13.5vw;
  }

  @include media-breakpoint-up(lg) {
    font-size: 7.5rem;
    line-height: 12rem;
  }
}

h2 {
  font-size: 11vw;
  line-height: 1;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    font-size: 6rem;
  }
}

h3,
h4 {
  font-size: 16vw;
  line-height: 24vw;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 13vw;
    line-height: 17vw;
  }

  @include media-breakpoint-up(lg) {
    font-size: 7.5rem;
    line-height: 12rem;
  }
}

p {
  font-family: $opensans;
  font-weight: 400;
  font-size: 3.3vw;
  line-height: 1.5;

  @include media-breakpoint-up(lg) {
    font-size: 1.4rem;
    line-height: 1.5;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.6rem;
    //line-height: 1.5;
  }
}

a {
  &:hover {
    color: $red;
  }
}
