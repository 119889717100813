.footer {
  background: $red;
  padding: 2rem;
  text-align: left;
  position: relative;
  width: 100%;
  z-index: 10;

  .doritos-logo {
    position: absolute;
    right: 2rem;
    top: -6.55rem;
    width: 16rem;
  }

  .footer-items {
    ul {
      display: inline-block;

      &:last-of-type {
        margin-top: 1rem;
        display: flex;
        li {
          margin-right: 3.1vw;
          margin-bottom: 0;
          a {
            img {
              width: 10vw;
            }
          }
        }
      }

      li {
        list-style: none;
        margin-bottom: 2rem;

        &:last-of-type {
          margin-bottom: 1rem;
        }
        a,
        span {
          text-transform: uppercase;
          font-family: $opensanscondensed;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 1;
          color: $white;
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    height: 11.5rem;
    padding: 4rem 2rem 0;

    .doritos-logo {
      right: 7rem;
      width: 20rem;
      top: -8.2rem;
    }

    .footer-items {
      width: calc(100% - 20rem);
      ul {
        &:last-of-type {
          margin-top: 0;
          float: right;
          li {
            margin-right: 1rem;
            a {
              img {
                width: 1.7rem;
              }
            }
          }
        }

        li {
          display: inline-block;
          margin-right: 3rem;
          margin-bottom: 1rem;

          &:last-of-type {
            display: block;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .footer-items {
      ul {
        &:last-of-type {
          li {
            margin-right: 1.5rem;
            a {
              img {
                width: 3rem;
              }
            }
          }
        }
      }
    }
  }
}
