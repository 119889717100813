.vision-board-page {
  padding: $navHeightMobile + 10vw 5vw 20vw;
  min-height: calc(100vh - 26.5rem);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .background-image-container {
    &:before {
      content: '';
      height: 10%;
      width: 100%;
      position: absolute;
      bottom: -0.1rem;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 1)
      );
    }

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
  }

  .search-input {
    align-self: center;
  }

  .vision-board {
    z-index: 2;
    margin: 10vw 0;
  }

  // 992 ->
  @include media-breakpoint-up(lg) {
    padding: $navHeightDesktop + 5rem 5rem 9rem;
    min-height: calc(100vh - 11.5rem);
    .vision-board {
      margin: 0 0 9rem;
    }
  }
}
