@import '~bootstrap/scss/bootstrap.scss';
@import 'src/style/variables.scss';
@import 'src/style/reset.scss';
@import 'src/style/typography.scss';
@import 'src/style/global.scss';
@import 'src/style/components/navigation.scss';
@import 'src/style/components/footer.scss';
@import 'src/style/components/modal.scss';

// import page scss
@import 'src/style/pages/home.scss';
@import 'src/style/pages/vision-board-page.scss';
@import 'src/style/pages/setup-profile.scss';
@import 'src/style/pages/general-page.scss';

// import component scss
@import 'src/style/components/login-button.scss';
@import 'src/style/components/background-image.scss';
@import 'src/style/components/background-video.scss';
@import 'src/style/components/items-list.scss';
@import 'src/style/components/hero.scss';
@import 'src/style/components/scrollable-container.scss';
@import 'src/style/components/featured-challenge.scss';
@import 'src/style/components/featured-creator.scss';
@import 'src/style/components/search-input.scss';
@import 'src/style/components/vision-board.scss';
@import 'src/style/components/vision-board-card.scss';
@import 'src/style/components/user.scss';
@import 'src/style/components/user-details.scss';
@import 'src/style/components/user-progress.scss';
@import 'src/style/components/user-stats.scss';
@import 'src/style/components/user-interests.scss';
@import 'src/style/components/user-challenges.scss';
@import 'src/style/components/edit-user.scss';
@import 'src/style/components/media-carousel.scss';
@import 'src/style/components/winner.scss';
@import 'src/style/components/challenge.scss';
@import 'src/style/components/tab-component.scss';
@import 'src/style/components/challenge-info.scss';
@import 'src/style/components/challenge-submissions.scss';
@import 'src/style/components/challenge-submission-form.scss';
@import 'src/style/components/challenge-submission-attachments.scss';
