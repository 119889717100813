.media-carousel {
  .carousel-container {
    padding: 0 4vw;
    &.one-slide {
      padding: 0 !important;
      .carousel {
        button {
          display: none;
        }
      }
    }

    .carousel {
      &.one-slide {
        button {
          display: none;
        }
      }

      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &.carousel__back-button {
          left: -4vw;
        }
        &.carousel__next-button {
          right: -4vw;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        img {
          width: 3vw;
        }
      }

      .carousel__slider {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .player-wrapper {
          position: relative;
          padding-top: 56.25%;
          .react-player {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .carousel-container {
      padding: 0 4rem;
      .carousel {
        button {
          img {
            width: 2rem;
            height: 2.2rem;
          }
        }
        button {
          &.carousel__back-button {
            left: -8rem;
          }
          &.carousel__next-button {
            right: -8rem;
          }
        }
      }
    }
  }
}
