.vision-board {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 25vw;

  .no-results {
    padding-top: 10vw;
    font-family: $anton;
    font-size: 6vw;
  }

  .card-holder {
    z-index: 1;
    margin-top: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 2vw;
  }

  .vision-board-background {
    z-index: 0;
    display: none;
    svg {
      position: absolute;
      filter: $dropshadow;
      height: auto;
      z-index: 1;
    }

    .red-hollow-chip {
      path {
        fill: $red;
      }
    }

    .green-hollow-chip {
      path {
        fill: $neon-green;
      }
    }
  }
  @include media-breakpoint-up(md) {
  }

  @include media-breakpoint-up(lg) {
    min-height: 25rem;

    .no-results {
      padding-top: 10rem;
      font-size: 3.2rem;
    }

    .card-holder {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      max-width: 175rem;
      margin-top: 4rem;
    }

    &.show-background {
      .vision-board-background {
        display: block;
      }
    }

    .vision-board-background {
      .red-hollow-chip {
        width: 21vw;
        top: 40%;
        right: 1vw;
        transform: rotate(-45deg);
      }

      .green-hollow-chip {
        width: 24vw;
        top: -20rem;
        left: 1vw;
        transform: rotate(-35deg);
      }
      .full-chip {
        right: 4vw;
        top: 0;
        width: 16vw;
        transform: rotate(220deg);
      }
    }
  }
}

.load-more {
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: none;
  }
}
