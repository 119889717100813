.user-stats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin: 10vw 0;
  z-index: 1;
  h3 {
    position: absolute;
    top: -10vw;
    margin-left: 2vw;
  }
  .content {
    margin: 2vw 0;
    width: 90%;
    background-color: $orange;
    padding: 10vw 5vw 2vw 2vw;

    .stat {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 5vw;

      img {
        width: 7vw;
        height: auto;
        margin-right: 3vw;
      }
      p {
        text-transform: uppercase;
        margin-right: 3vw;

        &:last-of-type {
          font-size: 5vw;
          margin: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    h3 {
      top: -7vw;
    }
  }

  @include media-breakpoint-up(lg) {
    margin: 5rem 0 6rem;

    h3 {
      top: -6rem;
      padding-left: 9%;
    }
    .content {
      margin: 0;
      width: 90%;
      padding: 6rem 10% 5rem;
      display: flex;

      .stat {
        margin-bottom: 0;
        align-items: flex-end;
        width: auto;
        margin-right: 4rem;

        img {
          margin-right: 1rem;
          width: 7.5rem;
        }
        p {
          margin-right: 2rem;
          &:last-of-type {
            font-size: 4rem;
            line-height: 1;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-top: 10rem;
  }
}
