.challenge-info {
  h1 {
    font-size: 7vw;
    line-height: 1;
    margin-bottom: 2vw;
    color: $neon-green;
  }
  .prize-info,
  .submission-info {
    width: 100%;
  }
  .prize-info {
    display: flex;
    justify-content: space-between;
  }
  .submission-interest-level {
    height: 7vw;
    width: 100%;
    margin-top: 4vw;
    background: $neon-green;
    display: flex;
    align-items: center;
    justify-content: center;
    p,
    label {
      color: $black;
      font-family: $opensanscondensed;
      font-weight: 700;
      margin-right: 3vw;
      font-size: 2.7vw;
    }
    ul {
      display: flex;

      li {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 3vw;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
          cursor: pointer;
        }

        label {
          cursor: pointer;
          color: $black;
          display: inline-block;
          margin: 0 3vw 0 0;
          padding-left: 1vw;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 3rem;
      margin-bottom: 0.5rem;
    }
    .submission-interest-level {
      margin-top: 2rem;
      padding-left: 0;
      height: 5rem;
      p,
      label {
        line-height: 1;
        margin-right: 5rem;
        font-size: 1.5rem;
      }

      ul {
        li {
          padding-left: 2rem;

          label {
            margin: 0 1.5rem 0 0;
            padding-left: 1rem;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .submission-interest-level {
      p,
      label {
        font-size: 1.6rem;
      }
    }
  }
}
