//@import '../variables.scss';

.nav {
  display: flex;
  flex-direction: column;
  padding: 0 0 2.5vw;
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  height: $navHeightMobile;

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-image: linear-gradient(
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0)
    );
  }

  .navbar-background {
    opacity: 0;
    height: 120%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0)
    );
  }

  .login-button-container {
    width: 100%;
  }

  .navbar-brand {
    display: block;
    width: 15%;
    margin: 0 auto;
    align-self: center;
    position: relative;
    padding: 3.5vw 0 3vw;
    filter: $dropshadow;
    img {
      display: block;
      width: 100%;
    }
  }

  .navbar-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .nav-item {
      width: 33.3333%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-family: $anton;
      text-transform: uppercase;
      font-size: 3vw;
      height: 8vw;
      overflow-y: visible;
      border-bottom: 0.3rem solid $neon-green;

      &:first-of-type {
        border-color: $orange;

        a.active,
        a:hover {
          border-bottom: 0.3rem solid $orange;
        }
      }

      &:last-of-type {
        border-color: $red;

        a.active,
        a:hover {
          border-bottom: 0.3rem solid $red;
        }
      }

      a {
        color: $white;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 10.5vw;
        filter: $dropshadow;

        &:hover {
          text-decoration: none;
        }

        &.active,
        &:hover {
          border-bottom: 0.3rem solid $neon-green;
        }

        &.active {
          &:before {
            transform: rotate(180deg);
            top: 1.5vw;
          }
        }

        &:before {
          content: '';
          display: block;
          background: url('../../assets/svg/red-triangle.svg');
          width: 1.6vw;
          height: 1.5vw;
          position: relative;
          left: -1.5vw;
          top: 1.3vw;
        }
      }
    }
  }

  // 992
  @include media-breakpoint-up(lg) {
    height: $navHeightDesktop;
    display: block;

    .navbar-background {
      height: 100%;
    }

    .navbar-brand {
      width: 11.5rem;
      position: absolute;
      left: 4rem;
      padding: 2.5rem 0;
    }

    .navbar-nav {
      width: 77rem;
      position: absolute;
      right: 0;
      bottom: 1rem;

      .nav-item {
        font-size: 2.5rem;
        height: 5rem;

        a {
          height: 6rem;

          &.active {
            &:before {
              top: 1.2rem;
            }
          }

          &:before {
            width: 1.7rem;
            height: 1.5rem;
            top: 1rem;
          }
        }
      }
    }
  }
}
