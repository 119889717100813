@media (min-width: 1600px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1540px;
  }
}

*:focus {
  outline: thin dotted !important ;
}

html {
  background-color: $black;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
main {
  position: absolute;
  top: 0;
  overflow: hidden;
  background-color: $black;
  width: 100%;
}

.background-image-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.error-component,
.maintenance-component {
  z-index: 100000;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 2rem;

  .btn.btn-primary {
    background-color: $red;
    border-color: $red;
  }

  * {
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 9vw;
    line-height: 1;

    @include media-breakpoint-up(lg) {
      font-size: 3.75rem;
    }
  }

  img {
    max-width: 20rem;
  }
}

.page-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;

  &.transparent {
    background-color: transparent;
  }

  * {
    position: absolute;
  }

  .spinner-border {
    width: 30vw;
    height: 30vw;
    border-width: 0.3rem;
    opacity: 0.5;
  }

  img {
    width: 10vw;
    opacity: 0.5;
  }
}

.component-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;

  &.transparent {
    background-color: transparent;
  }

  .spinner-border {
    width: 5rem;
    height: 5rem;
    border-width: 0.3rem;
    opacity: 0.5;
  }
}

form {
  margin: 2rem 0;

  &.was-validated {
    select.form-control:valid,
    select.form-control:invalid {
      background-image: none;
    }
  }

  .form-group {
    text-align: left;
    .input-group-append {
      background: url('../assets/svg/red-triangle.svg');
      width: 3vw;
      height: 2.7vw;
      margin-left: -0.1rem;
      position: absolute;
      right: 1.1rem;
      z-index: 10;
      top: 50%;
      pointer-events: none;
      transform: translateY(-50%) rotate(180deg);
    }

    .form-label {
      font-size: 4vw;
      line-height: 1;
      margin-bottom: 2vw;
      text-transform: capitalize;
    }
    .form-control {
      font-size: 4vw;

      &:disabled,
      &[readonly] {
        background-color: $grey;
      }
    }
    .form-check {
      display: flex;
      margin: 0;
      flex-direction: column;
      justify-content: center;

      .form-check-label {
        margin-left: 1rem;
        font-size: 4vw;
      }
    }
  }
}

select {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.radio-button,
.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 3vw;
  width: 3vw;
  left: 0;
  z-index: 2;

  &:checked {
    ~ .radio-button-checkmark,
    ~ .checkbox-checkmark {
      &:after {
        display: block;
      }
    }
  }

  &:invalid {
    ~ .radio-button-checkmark,
    ~ .checkbox-checkmark {
      outline: 1px solid $red;
    }
  }
}

.radio-button-checkmark,
.checkbox-checkmark {
  position: absolute;
  pointer-events: none;
  left: 0;
  height: 3vw;
  width: 3vw;
  background-color: $white;
  //border-radius: 1.5vw;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 0.25vw;
    top: 0.25vw;
    width: 2.5vw;
    height: 2.5vw;
    //border-radius: 1.25vw;
    background: $black;
  }
}

.checkbox-checkmark {
  &:after {
    background-size: 4.5vw 4.5vw;
    width: 4.5vw;
    height: 4.5vw;
    top: -1.6vw;
    background-color: transparent;
    background-image: url('../assets/svg/checkmark.svg');
  }
}

.radio-button-checkmark {
  border-radius: 1.5vw;

  &:after {
    border-radius: 1.25vw;
  }
}

.error {
  color: red;
}
.invalid-feedback {
  font-size: 3.3vw;
  filter: drop-shadow(1px 1px 1px $black);
}

.btn {
  border-radius: 0;
  padding: 0;
  margin: 0;

  &.btn-primary {
    font-family: $anton;
    font-size: 7vw;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    background-color: $pink;
    border-color: $pink;

    &:active {
      background-color: $pink !important;
      border-color: $pink !important;
    }
  }

  &.btn-secondary {
    font-family: $opensanscondensed;
    font-weight: 700;
    background-color: transparent;
    font-size: 5vw;
    padding: 0 3vw;
    border: 0;
    border-bottom: 2px solid $white;
    text-transform: uppercase;

    &:active,
    &:hover {
      border-color: $red;
      background-color: $red !important;
    }
  }
}

.avatar-container {
  position: relative;

  &:before {
    content: '';
    background: #fd5e00;
    width: 95%;
    height: 110%;
    left: 10%;
    top: -5%;
    display: block;
    position: absolute;
  }

  .avatar {
    width: 100%;
    position: relative;
  }
}

.graphics {
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  svg {
    position: absolute;
    filter: $dropshadow;
    height: auto;
    z-index: 1;
  }
}

.image-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black;
  position: relative;

  &.is-background {
    position: absolute;

    .spinner-border {
      width: 30vw;
      height: 30vw;
      border-width: 0.3rem;
      opacity: 0.5;
    }
  }
}

@include media-breakpoint-up(md) {
  .btn.btn-primary,
  .btn.btn-secondary {
    font-size: 3rem;
  }
}
@include media-breakpoint-up(lg) {
  .btn.btn-primary,
  .btn.btn-secondary {
    font-size: 2.25rem;
  }
  .invalid-feedback {
    font-size: 1.4rem;
  }
  form {
    .form-group {
      .input-group-append {
        width: 1.2rem;
        height: 1.1rem;
      }
      .form-label {
        font-size: 2.25rem;
        margin-bottom: 1.5rem;
      }
      .form-control {
        font-size: 2.25rem;
      }
      .form-check {
        .form-check-label {
          font-size: 2.25rem;
        }
      }
    }
  }

  .radio-button,
  .checkbox {
    height: 2rem;
    width: 2rem;
  }
  .radio-button-checkmark,
  .checkbox-checkmark {
    height: 2rem;
    width: 2rem;

    &:after {
      width: 1.6rem;
      height: 1.6rem;
      top: 0.2rem;
      left: 0.2rem;
    }
  }

  .checkbox-checkmark {
    &:after {
      background-size: 3.5rem 3.5rem;
      width: 3.5rem;
      height: 3.5rem;
      top: -1.6rem;
      left: 0.2rem;
    }
  }
  .radio-button-checkmark {
    border-radius: 1rem;
  }

  .btn.btn-secondary {
    padding: 0 2rem;
  }
  .modal-dialog {
    max-width: 85%;
  }
}

@media (min-width: 1200px) {
  .invalid-feedback {
    font-size: 2.2rem;
  }
}

// FIXES FOR SAFARI DESKTOP -- SOLVES SLOW PERFORMANCE 
sup {
  position: unset;
  display: inline-block;
  vertical-align: top;
  font-size: 60% !important;
  padding-top: 4px;
}

.vision-board-card .details {
  padding-bottom: 5em !important;
}

.vision-board-card .btn {
  position: unset !important;
  margin-top: 60px;
  margin-bottom: 0;
}

.vision-board-card .details .user-info img,
.vision-board-card .details .item-info img {
  position: unset !important;
}