.featured-creator {
  width: 100%;
  padding: 5rem 0;
  margin-bottom: 20vw;
  position: relative;
  text-align: left;
  background: $grey-2;

  .graphics {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;

    .double-chips {
      display: none;
    }
  }

  .title-bar {
    padding-left: 5vw;
    position: relative;
    h3 {
      font-family: $opensanscondensed;
      font-weight: 300;
      font-size: 11vw;
      line-height: 5vw;
      letter-spacing: -0.8vw;
      text-transform: uppercase;
      margin-bottom: -2vw;
      z-index: 2;
      position: relative;
    }
    h4 {
      z-index: 2;
      position: relative;
      // margin: 0;
      width: 90%;
      line-height: .95em;
      margin: 20px 0 15px;
    }
    .title-bar-background {
      position: absolute;
      left: 0;
      // bottom: 0;
      z-index: 1;
      background: $orange;
      // height: 5rem;
      height: 75%;
      width: 100%;
      bottom: -15px;
    }
  }
  .image-holder {
    position: relative;
    z-index: 1;
    .hollow-chip {
      position: absolute;
      width: 150vw;
      left: -30vw;
      top: 60%;
      z-index: 2;
      transform: translateY(-50%) rotate(-10deg);
      path {
        fill: $light-blue;
      }
    }
    .background-image {
      position: relative;
      min-height: 150vw;
    }
  }
  .info-holder {
    position: relative;
    z-index: 2;
    padding: 0 5vw;
    .btn {
      background-color: $red;
      border-color: $red;
      margin-top: -6vw;
      margin-bottom: 3vw;
      width: 28.5rem;
      z-index: 2;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .title-bar {
      h3 {
        margin-bottom: 0;
      }
      h4 {
        margin: 0;
        line-height: 1.6em;
      }
      .title-bar-background {
        height: 7rem;
        bottom: 0;
      }
    }
    .info-holder {
      .btn {
        margin-top: -3.5vw;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 20rem;

    .graphics {
      .double-chips {
        display: block;
        position: absolute;
        bottom: -41.5px;
        left: 35%;
        filter: none;
        width: 25rem;
      }
    }
    .title-bar {
      h3 {
        font-size: 4.5rem;
        line-height: 1;
        letter-spacing: -0.4rem;
        margin-bottom: -2.3rem;
      }
      h4 {
        // line-height: 12vw;
      }
      .title-bar-background {
        // width: 95;
      }
    }
    .image-holder {
      position: absolute;
      width: 35%;
      height: 100%;
      right: 5vw;
      top: 4.5rem;
      .hollow-chip {
        width: 55vw;
        left: auto;
        right: -12vw;
      }
      .background-image {
        min-height: auto;
      }
    }
    .info-holder {
      width: 55%;
      p {
        margin-top: 3rem;
      }
      .btn {
        margin-top: 3rem;
      }
    }
  }
}
