.vision-board-card {
  width: 100%;
  min-height: 130vw;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  position: relative;
  background-color: $black;

  .card-image {
    width: 100%;
    object-fit: cover;
  }

  .card-header {
    position: absolute;
    font-family: $opensanscondensed;
    font-weight: 700;
    font-size: 5vw;
    line-height: 1;
    height: 8vw;
    width: 90%;
    z-index: 1;
    text-align: left;
    padding: 0 4vw;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      &:last-of-type:not(:only-of-type) {
        float: right;
      }
    }
  }

  .bottom-border {
    position: absolute;
    width: 100%;
    height: 3vw;
    bottom: 0;
    z-index: 1;
  }

  .info-label {
    font-family: $opensanscondensed;
    font-weight: 700;
  }

  // CHALLENGE CARD
  &.challenge {
    .card-header,
    .bottom-border {
      background-color: $neon-green;
      color: $black;
    }
  }

  // WINNER CARD
  &.winner {
    .card-header,
    .bottom-border {
      background-color: $red;
    }
  }
  // WINNER CARD WITH FULL BACKGROUND IMAGE
  &.full {
    .card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .image-loader {
      position: absolute;
    }
  }
  &.full,
  &.partial {
    .details {
      filter: $dropshadow;
      position: relative;
      height: 100%;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .card-title {
        font-family: $anton;
        font-size: 6vw;
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 5vw;
      }

      .user-info,
      .item-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-transform: uppercase;

        img {
          width: 9.3vw;
          height: 9.3vw;
          position: relative;
          margin-bottom: 1vw;
        }
      }

      .user-info {
        margin-top: 10vw;
        img {
          border-radius: 0.3rem;
        }
      }

      .item-info {
        flex-direction: row;
        margin: 7vw 0 15vw;

        div {
          width: 33.333%;
        }
      }
    }
  }

  &.full {
    .details {
      min-height: 140vw;
    }
  }

  // WINNER CARD WITH SHORTENED BACKGROUND IMAGE
  &.partial {
    .card-image {
      flex: 1;
      width: 100%;
    }

    .details {
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;
      height: auto;
      padding: 0 2vw 20vw;

      .title-bar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5vw;
        .card-title {
          margin-bottom: 0;
        }
        img {
          width: 9.3vw;
          height: 9.3vw;
        }
      }

      .description {
        margin-top: 3vw;
      }

      .user-info {
        margin-top: 3vw;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;

        img {
          margin: 0 1rem 0 0;
        }
      }

      .item-info {
        margin: 2vw 0;

        div {
          display: flex;
          width: 100%;
          align-items: center;

          img {
            margin-right: 3vw;
          }
        }
      }

      .prize-info {
        margin-top: 3vw;
        width: 100%;
        // p {
        //   &:first-of-type {
        //     float: left;
        //   }
        //   &:last-of-type {
        //     float: right;
        //   }
        // }
      }
      .btn {
        align-self: center;
        position: absolute;
        bottom: 8vw;
      }
    }
  }

  // 992 ->
  @include media-breakpoint-up(lg) {
    width: 30%;
    margin-right: 5%;
    min-height: auto;

    &:nth-child(3n) {
      margin-right: 0;
    }

    .card-header {
      height: 3.5rem;
      padding: 0 1rem;
      font-size: 1.75rem;
    }

    .bottom-border {
      height: 1rem;
    }

    &.full {
      .details {
        min-height: 60rem;
      }
    }

    &.full,
    &.partial {
      .details {
        .card-title {
          font-size: 2.25rem;
          margin-bottom: 2rem;
        }
        .user-info,
        .item-info {
          img {
            width: 3.75rem;
            height: 3.75rem;
            position: relative;
            margin-bottom: 0.5rem;
          }
        }
        .user-info {
          margin-top: 3rem;
        }
        .item-info {
          margin: 4rem 0;
        }
        .btn {
          font-size: 2.2rem;
          bottom: 4rem;
        }
      }
    }
    &.partial {
      .details {
        padding: 0 2rem 10rem;
        .title-bar {
          margin-top: 3rem;
          img {
            height: 3.75rem;
            width: 3.75rem;
          }
        }
        .item-info {
          margin: 1rem 0;
          div {
            img {
              margin-right: 2rem;
            }
          }
        }
        .user-info {
          img {
            margin-right: 2rem;
          }
        }
        .description {
          margin-top: 2rem;
        }
        .prize-info {
          margin-top: 2rem;

          // p {
          //   //font-size: 2rem;

          //   &:last-of-type {
          //     float: left;
          //     clear: left;
          //   }
          // }
        }
      }
    }
  }

  // 1200 ->
  @include media-breakpoint-up(xl) {
    .card-header {
      padding: 0 2rem;
      font-size: 2.25rem;
    }
  }

  // @media (min-width: 1300px) {
  //   min-height: 80rem;
  //   &.partial {
  //     .details {
  //       .prize-info {
  //         p {
  //           &:last-of-type {
  //             float: right;
  //             clear: none;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
