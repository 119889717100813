.user-challenges {
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: 8vw;
    line-height: 1.5;
    margin-bottom: 5vw;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 5rem;
    h3 {
      font-size: 7.5rem;
      margin-bottom: 4rem;
    }
  }
}
