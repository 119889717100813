.edit-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: $navHeightMobile + 10vw;
  min-height: calc(100vh - 26.5rem);
  padding-bottom: 20vw;
  text-align: left;
  position: relative;

  h1,
  h2 {
    color: $yellow;
  }

  h1 {
    font-size: 10vw;
    line-height: 1.2;
  }

  h2 {
    text-transform: capitalize;
    font-size: 6vw;
    line-height: 1.2;
  }

  .background-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    .background-image-cover {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $black;
      opacity: 0.3;
    }
  }

  .edit-user-info-address {
    form {
      &.was-validated {
        select.form-control:valid,
        select.form-control:invalid {
          background-image: none;
        }
      }
    }
  }

  .edit-user-opt-ins {
    margin: 3vw 1vw 10vw;
    .form-group {
      margin-bottom: 1vw;
      position: relative;
    }
    label {
      padding-left: 4vw;
      position: relative;
      top: 0.6vw;
    }
  }

  .edit-user-avatar,
  .edit-user-header,
  .edit-user-interests {
    margin-bottom: 5vw;
  }

  .edit-user-avatar,
  .edit-user-header {
    .scrollable-container {
      ul {
        li {
          margin: 0 1vw;
          background: $black;

          &:hover {
            img {
              opacity: 1;
            }
          }

          &.active {
            outline: 4px solid $red;
          }

          .image-loader {
            height: 15vw;
          }
        }
      }
    }
  }

  .edit-user-avatar {
    .scrollable-container {
      ul {
        li {
          width: 20vw;
        }
      }
    }
  }

  .edit-user-header {
    .scrollable-container {
      ul {
        li {
          width: 30vw;
        }
      }
    }
  }

  .edit-user-interests {
    span {
      cursor: pointer;
      display: block;
      //text-decoration: underline;
      font-size: 4vw;
      padding-left: 3.5vw;
      font-family: $opensanscondensed;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }

      &:before {
        content: '';
        display: block;
        background: url('../../assets/svg/red-triangle.svg');
        width: 3vw;
        height: 2.7vw;
        position: relative;
        left: -4.3vw;
        top: 4.2vw;
        transform: rotate(-30deg);
      }
    }
  }

  .edit-user-buttons {
    .btn {
      width: 45%;
      background-color: $red;
      border-color: $red;
      font-size: 5vw;

      &:first-of-type {
        margin-right: 10%;
        color: $black;
        background-color: $neon-green;
        border-color: $neon-green;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: $navHeightDesktop + 10rem;
    min-height: calc(100vh - 11.5rem);
    padding-bottom: 9rem;

    .container {
      .row {
        > div {
          width: 45%;
          &:first-of-type {
            margin-right: 2.5%;
            flex: 1;
          }
          &:last-of-type {
            margin-left: 2.5%;
            flex: 0;
          }
        }
      }
    }

    h1 {
      font-size: 4rem;
    }

    h2 {
      font-size: 3rem;
    }

    .right-side {
      margin-top: 7rem;
    }

    .edit-user-opt-ins {
      margin: 3rem 0.5rem 0;

      .form-group {
        margin-bottom: 2rem;
      }

      label {
        padding-left: 4rem;
        font-size: 1.5rem;
        line-height: 1.3;
        top: 0;
      }
    }

    .edit-user-avatar,
    .edit-user-header,
    .edit-user-interests {
      margin-bottom: 5rem;
    }

    .edit-user-avatar,
    .edit-user-header {
      .scrollable-container {
        .scroller-button {
          &:first-of-type {
            left: -1rem;
          }
          &:last-of-type {
            right: -1rem;
          }
        }
        ul {
          li {
            margin: 0 1rem;
          }
        }
      }
    }

    .edit-user-avatar {
      .scrollable-container {
        ul {
          li {
            width: 15rem;

            .image-loader {
              height: 15rem;
            }
          }
        }
      }
    }

    .edit-user-header {
      .scrollable-container {
        ul {
          li {
            width: 20rem;

            .image-loader {
              height: 15rem;
            }
          }
        }
      }
    }

    .edit-user-interests {
      .items-list {
        li {
          font-size: 2rem;
          margin-bottom: 0;
        }
      }
      span {
        font-size: 2rem;
        padding-left: 1.4rem;
        line-height: 1;

        &:before {
          width: 1.2rem;
          height: 1rem;
          left: -1.8rem;
          top: 1.4rem;
        }
      }
    }

    .edit-user-buttons {
      .btn {
        width: 15rem;
        font-size: 2rem;

        &:first-of-type {
          margin-right: 2rem;
        }
      }
    }
  }
}
