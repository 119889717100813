.items-list {
  &.allow-select {
    li {
      cursor: pointer;
    }
  }

  li {
    font-size: 4vw;
    margin-bottom: 1vw;
    text-transform: uppercase;

    &.active {
      color: $yellow;
    }
  }

  // 992 ->
  @include media-breakpoint-up(lg) {
    li {
      display: inline-flex;
      font-size: 3rem;
      margin-right: 2rem;
    }
  }
}
