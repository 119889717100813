.challenge-submission-form {
  .form-row {
    .form-group {
      margin-bottom: 5vw;
      .form-label {
        color: $neon-green;
        margin-bottom: 3vw;
        font-family: 'Open Sans Condensed';
        font-weight: 700;

        .max-attachments {
          display: inline-block;
          margin-left: 1rem;
        }
      }
      .form-control {
        color: $grey-3;
      }
    }
  }

  .btn {
    background-color: $neon-green;
    border-color: $neon-green;
    color: $black;

    &:last-of-type:not(:only-of-type) {
      margin-left: 3vw;
      background-color: $grey-2;
      border-color: $grey-2;
    }
  }

  .items-list {
    li {
      font-size: 3.5vw;
      display: inline-block;
      margin-right: 2vw;
    }
  }

  &.editing {
    .form-label {
      color: $white;
    }
  }

  @include media-breakpoint-up(lg) {
    form {
      margin-bottom: 0;
      .form-row {
        &.tags-row {
          margin-top: -1rem;
        }

        .form-group {
          margin-bottom: 2rem;
          .form-label {
            margin-bottom: 0.5rem;

            &.attachments-label {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }

    .items-list {
      margin-top: 1rem;
      li {
        font-size: 2.25rem;
        line-height: 1;
        margin-bottom: 1rem;
        margin-right: 1rem;
      }
    }
    .btn {
      width: 15rem;

      &:last-of-type:not(:only-of-type) {
        margin-left: 2rem;
      }
    }
  }
}
