.challenge-submissions {
  margin-top: 5vw;
  padding-top: 10vw;
  border-top: 1px solid $neon-green;

  h2 {
    font-size: 6vw;
    line-height: 1;
    display: inline;
  }

  p {
    font-family: $opensanscondensed;
    font-weight: 700;

    &.max-entries {
      line-height: 1;
      margin-left: 1rem;
      display: inline-block;
    }
  }

  .new-submission {
    cursor: pointer;
    position: relative;
    font-family: $opensanscondensed;
    font-weight: 700;
    font-size: 2vw;
    margin-top: 1vw;
    color: $neon-green;
    text-decoration: underline;
  }

  .submission-buttons {
    display: flex;

    div {
      display: flex;
      align-items: center;
      font-size: 3vw;
      font-family: $opensanscondensed;
      font-weight: 700;
      justify-content: center;
      padding: 2vw 3vw;
      margin-top: 2vw;
      user-select: none;
      cursor: pointer;
      background-color: $black;

      &.active {
        background-color: $neon-green;
        color: $black;
      }
    }
  }

  .submission-entries {
    position: relative;
    .submission-details {
      position: relative;
      display: none;

      &.active {
        display: block;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 2.5rem;
    padding-top: 3rem;
    h2 {
      font-size: 3rem;
    }
    .new-submission {
      font-size: 1.6rem;
      margin-top: 1.5rem;
    }
    p {
      bottom: 1rem;
    }

    .submission-buttons {
      div {
        font-size: 2.2rem;
        padding: 1rem 2rem;
        margin-top: 2rem;
      }
    }
  }
}
