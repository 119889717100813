.winner-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $navHeightMobile + 10vw 5vw 20vw;

  .graphics {
    display: none;
  }

  .media-carousel {
    margin-bottom: 5vw;
  }

  .content {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    h1 {
      color: $red;
      font-size: 7vw;
      line-height: 1;
      margin-bottom: 2vw;
    }

    p {
      margin-bottom: 4vw;
      &.challenge-info {
        span {
          display: inline-block;

          &:first-of-type {
            margin-right: 3vw;
          }
          em {
            color: $red;
            font-style: normal;
          }
        }
      }
    }

    .tags {
      margin-bottom: 4vw;
      width: 100%;
      p {
        color: $red;
        margin-bottom: 2vw;
        font-weight: 700;
        font-family: 'Open Sans Condensed';
        font-size: 5vw;
      }
      .items-list {
        border-bottom: 0.1rem solid $red;
        padding-bottom: 2vw;
        li {
          font-size: 3vw;
          display: inline-block;
          margin-right: 3vw;
        }
      }
    }

    .user-info {
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
      width: 100%;
      .avatar {
        display: inline-flex;
        align-items: center;
        margin-bottom: 2vw;
        .avatar-container {
          width: 10vw;
          height: 10vw;
        }
        span {
          color: $red;
          font-family: $opensanscondensed;
          font-weight: bold;
          font-size: 4vw;
          margin-left: 2vw;
        }
      }

      .social {
        display: inline-flex;
        top: -1vw;
        position: relative;
        .likes {
          user-select: none;
          cursor: pointer;
          //border: 0.2rem solid $red;
          background-color: $white;
          color: $red;
          display: flex;
          align-items: center;
          height: 11.8vw;
          padding: 0 3vw;

          &.not-logged-in {
            cursor: default;
          }

          &.liked {
            color: $white;
            background-color: $red;
            cursor: default;
            svg {
              path {
                fill: $white;
              }
            }
          }
          span {
            font-family: $opensanscondensed;
            font-weight: 700;
            font-size: 4vw;
          }
          svg {
            width: 5vw;
            margin-left: 1vw;
            path {
              fill: $red;
            }
          }
        }
        button {
          svg {
            width: 10vw;
            height: 10vw;
            margin-left: 2vw;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: $navHeightDesktop + 10rem;
    padding-bottom: 11rem;

    .graphics {
      display: block;

      .blue-hollow-chip {
        width: 18vw;
        right: 10vw;
        top: 63%;
        transform: rotate(115deg);
        path {
          fill: $light-blue;
        }
      }
      .red-hollow-chip {
        width: 16vw;
        right: 10vw;
        transform: rotate(335deg);
        top: 75%;
        path {
          fill: $red;
        }
      }
    }

    .media-carousel {
      margin-bottom: 5rem;
    }

    .content {
      h1 {
        font-size: 7.5rem;
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 2rem;
      }
      .tags {
        margin-bottom: 3rem;
        p {
          font-size: 3.75rem;
          margin-bottom: 1rem;
        }
        .items-list {
          padding-bottom: 1rem;
          li {
            font-size: 2.25rem;
            margin-right: 2rem;
            margin-bottom: 1.5rem;
            line-height: 1;
          }
        }
      }
      .user-info {
        .avatar {
          margin: 0;
          .avatar-container {
            width: 6rem;
            height: 6rem;
          }
          span {
            font-size: 3rem;
            margin-left: 2rem;
          }
        }
        .social {
          top: 0;
          align-items: center;
          .likes {
            height: 6rem;
            padding: 0 2rem;
            span {
              font-size: 3rem;
            }
            svg {
              width: 3.75rem;
              height: 3.75rem;
              margin-left: 2rem;
            }
          }
          button {
            svg {
              width: 6rem;
              height: 6rem;
              margin-left: 2rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .user-info {
      .avatar {
        .avatar-container {
          width: 15rem;
          height: 15rem;
        }
        span {
          font-size: 5rem;
        }
      }
      .social {
        .likes {
          height: 15rem;
          padding: 0 3rem;
          span {
            font-size: 6rem;
          }
          svg {
            margin-left: 3rem;
          }
        }
        button {
          svg {
            width: 15rem;
            height: 15rem;
          }
        }
      }
    }
  }
}
