.general-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $navHeightMobile + 10vw 5vw 20vw;
  min-height: calc(100vh - 26.5rem);

  .graphics {
    display: none;
  }

  .content {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 8vw;
      line-height: 1;
      margin-bottom: 3vw;
    }

    > div {
      > * {
        margin-bottom: 2vw;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: $navHeightDesktop + 5rem 5rem 9rem;
    min-height: calc(100vh - 11.5rem);

    .graphics {
      display: block;

      .blue-hollow-chip {
        width: 38vw;
        left: -16vw;
        bottom: -4vw;
        path {
          fill: $light-blue;
        }
      }
    }

    .content {
      max-width: 80rem;
      h1 {
        font-size: 7rem;
        margin-bottom: 3rem;
      }

      a {
        color: $orange;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      > div {
        text-align: left;
        > * {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
