.setup-profile {
  min-height: calc(100vh - 26.5rem);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .background-image-container {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &:before {
      content: '';
      height: 10%;
      width: 100%;
      position: absolute;
      bottom: -0.1rem;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 1)
      );
    }
  }

  .content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30vw 0;

    .content-holder {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 3vw;
        background: $light-blue;
      }

      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
    }

    h2 {
      font-size: 10vw;
      line-height: 1;
    }

    .scrollable-container {
      margin-top: 2rem;
      width: 90%;
      max-width: 80rem;
      ul {
        overflow-y: visible;

        li {
          width: 30vw;
          margin: 0 1vw;
          background-color: $black;
          transition: transform 0.2s ease-in-out;
          &:hover {
            transform: scale(1.05);
            img {
              opacity: 1;
            }
          }
        }
      }
    }

    &.username-screen {
      .optins {
        position: relative;
        margin-top: 1vw;

        .optin {
          margin-bottom: 1vw;
          min-height: 4vw;

          a {
            color: $red;
          }
        }
        .form-group {
          margin-bottom: 0;
        }

        label {
          padding-left: 4vw;
          position: relative;
          top: 0.6vw;
        }
      }
    }

    &.avatar-screen,
    &.profile-header-screen {
      .scrollable-container {
        ul {
          li {
            .image-loader {
              height: 30vw;
            }
          }
        }
      }
    }

    &.profile-header-screen {
      .scrollable-container {
        ul {
          li {
            width: 100%;
          }
        }
      }
    }

    &.address-screen,
    &.socials-screen,
    &.favorites-screen {
      .form-row {
        .form-group {
          width: 100%;
          margin-bottom: 2rem;
        }
      }
    }

    &.address-screen,
    &.socials-screen,
    &.favorites-screen,
    &.interests-screen {
      .next-prev-buttons {
        margin-top: 2vw;
      }
    }

    .items-list {
      margin-top: 3rem;
    }

    form {
      width: 85%;
      max-width: 90rem;
      margin-top: 3rem;

      .form-group {
        label {
          text-transform: uppercase;
        }
      }
    }

    .btn {
      margin-top: 3rem;
      background-color: $neon-green;
      border-color: $neon-green;

      &.error {
        background-color: $red;
        border-color: $red;
      }
    }

    .next-prev-buttons {
      margin-top: 3rem;
      font-size: 4vw;
      span {
        display: inline-block;
        cursor: pointer;
        text-decoration: underline;
        &:not(:only-of-type) {
          margin: 0 1rem;
        }
      }
    }
  }

  // 992 ->
  @include media-breakpoint-up(lg) {
    //padding: $navHeightDesktop + 5rem 5rem 9rem;
    min-height: calc(100vh - 11.5rem);
    .content {
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15rem 0;

      h2 {
        width: auto;
        font-size: 6rem;
      }

      .scrollable-container {
        width: 60%;
        .scroller-button {
          width: 4rem;
          height: 4rem;
          &:first-of-type {
            left: -2rem;
          }
          &:last-of-type {
            right: -2rem;
          }
        }
        ul {
          max-width: 90rem;
          li {
            width: 12.5rem;
            margin: 0 1rem;
          }
        }
      }

      &.username-screen {
        .optins {
          margin-top: 1rem;

          .optin {
            margin-bottom: 1rem;
            min-height: 4rem;
          }
          .form-group {
            margin-bottom: 0;
            min-height: 4rem;
          }

          label {
            padding-left: 4rem;
            font-size: 1.5rem;
            line-height: 1.3;
            top: 0;
          }
        }
      }

      &.avatar-screen {
        .scrollable-container {
          ul {
            li {
              .image-loader {
                height: 12.5rem;
              }
            }
          }
        }
      }

      &.profile-header-screen {
        .scrollable-container {
          ul {
            li {
              width: 40%;
              .image-loader {
                width: 40rem;
              }
            }
          }
        }
      }

      &.address-screen,
      &.socials-screen,
      &.favorites-screen,
      &.interests-screen {
        .next-prev-buttons {
          margin-top: 2rem;
        }
      }

      .items-list {
        width: 80%;
        max-width: 110rem;
      }

      .btn {
        margin-top: 5rem;
        color: $black;
      }

      .next-prev-buttons {
        margin-top: 5rem;
        font-size: 2rem;
      }
    }
  }
}
