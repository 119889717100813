.user-progress {
  margin: 15vw 0 5vw;
  z-index: 2;

  .progress-total {
    border: 1px solid $white;
    border-left: none;
    background-color: $black;
    width: 80vw;
    height: 8vw;
    position: relative;
    margin-bottom: 3vw;

    .progress-next-level {
      position: absolute;
      right: 0;
      height: 100%;
      width: 4vw;
      background-color: $yellow;
    }

    .progress-indicator {
      position: absolute;
      z-index: 10;

      svg {
        width: 15vw;
        height: 15vw;
        top: -5vw;
        position: absolute;
        left: -7.5vw;
      }
      span {
        position: absolute;
        left: -10vw;
        top: -9vw;
        text-transform: uppercase;
        width: 20vw;
        font-size: 3vw;
      }
    }

    .progress-current {
      background-image: linear-gradient(to right, $dark-blue, $light-blue);
      height: 100%;
      width: 0;
      position: absolute;
      top: 0;
    }
  }
  p {
    font-size: 3vw;
    text-transform: uppercase;
  }

  @include media-breakpoint-up(lg) {
    margin: 20rem 0 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .progress-total {
      height: 1.5rem;
      margin-bottom: 1.5rem;
      max-width: 100rem;
      border-left: 1px solid $white;

      .progress-next-level {
        width: 3rem;
      }

      .progress-indicator {
        svg {
          width: 3.5rem;
          height: 3.5rem;
          top: -1.8rem;
          left: -1.75rem;
        }
        span {
          font-size: 1.8rem;
          left: -8rem;
          width: 16rem;
          top: -5rem;
        }
      }
    }
    p {
      font-size: 2rem;
    }
  }
}
