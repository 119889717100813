.modal-backdrop {
  backdrop-filter: blur(6px);
  background-color: transparent;
  &.show {
    opacity: 1;
  }
}
.modal-dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: none;
  .modal-body {
    background-color: $black;
  }
}

.notification-modal {
  .modal-dialog {
    max-width: 40rem;

    .modal-content {
      align-items: center;
      background-image: linear-gradient(
        to right top,
        rgba(51, 51, 51, 0.95),
        rgba(17, 17, 17, 0.75)
      );

      .modal-header {
        align-items: center;
        justify-content: center;
        background-color: $orange;
        width: 100%;
        height: 5rem;
        border: none;

        .close {
          position: absolute;
          right: 1rem;
          opacity: 1;

          span:first-of-type {
            color: $white;
            font-size: 3rem;
          }
        }
      }
      .modal-body {
        background-color: transparent;

        h2 {
          line-height: 1;
          font-size: 4rem;
          margin-top: 2rem;
        }
      }
      .modal-footer {
        border: none;

        .btn {
          padding: 0 4rem;
          margin-bottom: 2rem;
          color: $white;
          background-color: transparent;
          border-color: $grey;
        }
      }
    }
  }
}
