.challenge-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $navHeightMobile + 20vw 5vw 20vw;
  text-align: left;
  height: 100%;
  position: relative;

  .background-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .graphics {
    display: none;
    top: 0;
    left: 0;
  }

  .content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .content-left {
      display: flex;
      margin-bottom: 7vw;

      .label-and-socials {
        z-index: 2;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0)
        );
        span {
          color: $black;
          font-size: 5vw;
          font-family: $anton;
          text-transform: uppercase;
          padding: 1vw 2vw;
          background-color: $neon-green;
          display: block;
          margin-bottom: 1vw;
        }
        .socials {
          display: flex;
          justify-content: space-between;
          svg {
            width: 11vw;
            height: 11vw;
          }
        }
      }

      .challenge-image-container {
        position: relative;
        width: 50vw;
        margin-left: -13vw;

        &:before {
          content: '';
          background: #fd5e00;
          width: 88%;
          height: 106%;
          left: 15%;
          top: -3%;
          display: block;
          position: absolute;
        }

        .image-loader {
          height: 50vw;
        }

        .challenge-image {
          width: 100%;
          position: relative;
        }
      }
    }

    .content-right {
      width: 100%;

      .sign-in-btn {
        margin-top: 25px;
      }
      .add-submission {
        margin-top: 0px;
      }
      .btn {
        background-color: $neon-green;
        border-color: $neon-green;
        color: $black;
      }
    }
  }

  .winners-section {
    text-align: center;
    margin-top: 5vw;
  }

  @include media-breakpoint-up(lg) {
    padding: $navHeightDesktop + 10rem 5rem 9rem;

    .graphics {
      display: block;

      .blue-hollow-chip {
        left: 0;
        top: 85rem;
        width: 40rem;
        path {
          fill: $light-blue;
        }
      }
      .red-hollow-chip {
        left: 0;
        top: 76rem;
        width: 30rem;
        transform: rotate(90deg);
        path {
          fill: $red;
        }
      }
    }

    .content {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      max-width: 155rem;
      .content-left {
        width: 40%;

        .label-and-socials {
          span {
            font-size: 3rem;
            padding: 1rem 2rem;
            margin-bottom: 1rem;
            line-height: 1;
          }
          .socials {
            position: relative;
            svg {
              width: 7.5rem;
              height: 7.5rem;
              margin: 0;
            }
          }
        }

        .challenge-image-container {
          width: 100%;
          margin-left: -25%;

          .image-loader {
            height: 40rem;
          }
        }
      }
      .content-right {
        width: 55%;
        margin-left: 5%;
      }
    }
    .winners-section {
      margin-top: 5rem;
    }
  }
}
