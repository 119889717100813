.scrollable-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 120rem;
  position: relative;
  z-index: 100;

  &.scroller-red,
  &.scroller-white {
    .scroller-button {
      fill: $red;
    }
    ul {
      li {
        &.active {
          span {
            background-color: $red;
          }
        }
      }
    }
  }

  &.scroller-white {
    .scroller-button {
      fill: $white;
    }
  }

  &.scroller-green {
    .scroller-button {
      fill: $neon-green;
    }
    ul {
      li {
        &.active {
          span {
            background-color: $neon-green;
            color: $black;
          }
        }
      }
    }
  }

  &.scroller-orange {
    .scroller-button {
      fill: $orange;
    }
    ul {
      li {
        &.active {
          span {
            background-color: $orange;
          }
        }
      }
    }
  }

  &.has-overflow {
    ul {
      width: 90%;
    }
    .scroller-button {
      display: block;
    }
  }

  .scroller-button {
    width: 3vw;
    cursor: pointer;
    display: none;
    opacity: 1;
    pointer-events: all;
    position: absolute;

    &.disabled {
      opacity: 0.25;
      pointer-events: none;
    }

    &:first-of-type {
      left: 0rem;
    }
    &:last-of-type {
      right: 0rem;
    }
  }

  ul {
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    z-index: 2;
    padding: 1rem 0;

    // (Hide the scrollbars)
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    li {
      display: inline-block;
      font-size: 3vw;
      line-height: 1;
      text-transform: uppercase;
      user-select: none;
      cursor: pointer;

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        span {
          background-color: $red;
        }
        img {
          opacity: 1;
        }
      }

      span {
        display: block;
        padding: 2vw 3vw;
        pointer-events: none;
      }

      img {
        opacity: 0.4;
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .scroller-button {
      width: 3rem;
    }
    ul {
      li {
        font-size: 2rem;
        margin: 0 3rem;

        span {
          padding: 1rem 2rem;
        }
      }
    }
  }
}
