.home {
  min-height: calc(100vh - 26.5rem);
  .featured-challenge-holder {
    scroll-margin: 100px;
  }
  .vision-board {
    margin: 15vw 0;
    padding: 0 4vw;

    .tags {
      ul {
        flex-direction: row;
        justify-content: space-around;
        li {
          &.active {
            color: $light-blue;
            border-bottom: 2px solid $light-blue;
            background-color: transparent;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 11.5rem);
    .vision-board {
      margin: 9rem 0;
      padding: 0 4rem;

      .tags {
        display: flex;
        ul {
          position: relative;
          width: 100%;
          max-width: 1600px;
          justify-content: space-evenly;
          flex-direction: row;
          display: flex;

          li {
            margin: 0;
          }
        }
      }
    }
  }
}
