.login-button {
  cursor: pointer;
  position: absolute;
  right: 2.5rem;
  z-index: 10;
  top: 3rem;

  &.loggedIn {
    width: 100%;
    left: 0;
    top: 5vw;
    padding: 0 2vw;

    p {
      span {
        &:first-of-type {
          float: left;
        }
        &:last-of-type {
          float: right;
        }

        a {
          display: block;
        }
      }
    }
  }

  p {
    text-transform: uppercase;
    color: $white;
    font-family: $anton;
    font-size: 3.9vw;

    a {
      color: $orange;
    }
  }

  .login-link {
    color: $orange;
    &:hover {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(lg) {
    right: 9.5rem;

    &.loggedIn {
      top: 3rem;
      position: absolute;
      width: auto;
      left: auto;
      right: 5.5rem;

      p {
        &:hover {
          span {
            &:last-of-type {
              display: inline-block;
            }
          }
        }

        span {
          float: none !important;

          &:last-of-type {
            display: none;
            margin-left: 2rem;
            &:hover {
              color: $orange;
              text-decoration: underline;
            }
          }

          a {
            display: inline;
          }
        }
      }
    }

    p {
      font-size: 2.5rem;
    }
  }
}
