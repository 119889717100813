.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  // @media (min-aspect-ratio: 16/9) {
  //   video {
  //     width: 100%;
  //     height: auto;
  //   }
  // }
  // @media (max-aspect-ratio: 16/9) {
  //   video {
  //     width: auto;
  //     height: 100%;
  //   }
  // }
}
