.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: visible;
  z-index: 10;

  .bottom-gradient {
    content: '';
    width: 100%;
    height: 60vh;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 1)
    );
    position: absolute;
    bottom: 0;
  }

  .graphics {
    .big-splash {
      left: 0;
      top: 36vw;
      width: 62vw;
    }

    .blue-hollow-chip {
      width: 48vw;
      right: -10vw;
      top: 26vw;
      path {
        fill: $light-blue;
      }
    }

    .green-hollow-chip {
      right: 1vw;
      width: 30vw;
      top: 88vh;
      transform: rotate(-32deg);
      z-index: 2;
      path {
        fill: $neon-green;
      }
    }

    .yellow-hollow-chip {
      width: 50vw;
      left: -4rem;
      transform: rotate(-15deg);
      top: 88vh;
      path {
        fill: $yellow;
      }
    }

    .mountains {
      top: 95vh;
      right: -16vw;
      z-index: 1;
    }

    .full-chip-one {
      left: 0;
      top: 45%;
      width: 40vw;
    }

    .full-chip-two {
      top: 60%;
      width: 40vw;
      right: -10vw;
    }
  }

  h1 {
    z-index: 10;
    position: relative;
    text-align: left;
    width: 70vw;
    padding-left: 5vw;
    margin-bottom: 10vw;
    text-transform: uppercase;
    line-height: 17.3vw;
    &:before {
      content: '';
      left: 0;
      width: 100%;
      height: 120%;
      background: $purple;
      display: block;
      position: absolute;
      z-index: -1;
      top: -3.1vw;
      opacity: 0.9;
    }
  }

  .intro {
    z-index: 10;
    position: relative;
    text-align: left;
    width: 90vw;
    padding: 0 5vw;
    p {
      font-size: 4vw;
      line-height: 5.5vw;
    }
    &:before {
      content: '';
      width: 100%;
      height: 130%;
      background: #fd5e00;
      display: block;
      position: absolute;
      z-index: -1;
      top: -2vw;
      opacity: 0.9;
      left: 0;
    }
  }

  button {
    position: relative;
    top: 3rem;
    width: 60vw;
  }

  .search-input {
    position: absolute;
    bottom: 16vh;
    align-self: center;
  }

  .scroll-button {
    position: absolute;
    cursor: pointer;
    bottom: 8vh;
    left: 50%;
    transform: translateX(-50%);
    width: 5vw;
  }

  // 768 ->
  @include media-breakpoint-up(md) {
    align-items: center;
    h1 {
      width: 75vw;
      text-align: center;
      margin-bottom: 2rem;
      line-height: 11rem;
      &:before {
        top: -2.5%;
        height: 105%;
      }
    }
    .intro {
      margin-bottom: 1rem;

      width: 60%;
      text-align: center;
      p {
        font-size: 2vw;
        line-height: 3vw;
      }
      &:before {
        top: -1vw;
        height: 130%;
      }
    }
    .graphics {
      .big-splash {
        top: 28vw;
      }
      .blue-hollow-chip {
        width: 70vw;
        right: -4vw;
        top: 24vw;
      }
      .green-hollow-chip {
        right: 0vw;
        width: 40vw;
        top: 72vh;
        transform: rotate(0);
      }
      .yellow-hollow-chip {
        width: 30vw;
        left: 0rem;
        transform: rotate(-35deg);
        top: 76vh;
      }
      .mountains {
        top: 77vh;
        right: -13vw;
        z-index: 1;
        width: 20rem;
      }
      .full-chip-one {
        width: 20vw;
        top: 55%;
      }
      .full-chip-two {
        display: none;
      }
    }
    button {
      top: 5rem;
      width: 20rem;
    }
    .search-input {
      position: relative;
      top: 8rem;
    }

    .scroll-button {
      position: relative;
      transform: none;
      left: 0;
      top: 13rem;
    }
  }

  // 992 ->
  @include media-breakpoint-up(lg) {
    min-height: 85rem;
    &.has-video {
      .bottom-gradient {
        height: 40%;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      }
    }

    .bottom-gradient {
      height: 60%;
    }

    .graphics {
      .big-splash {
        top: 15rem;
        width: 60rem;
      }
      .blue-hollow-chip {
        width: 50rem;
        top: 10rem;
        left: 55%;
        right: auto;
      }
      .green-hollow-chip {
        bottom: -20vw;
        top: auto;
        width: 30vw;
      }
      .yellow-hollow-chip {
        top: auto;
        bottom: -20vw;
        width: 25vw;
      }
      .mountains {
        right: -5vw;
        width: 20vw;
      }
    }

    h1 {
      min-width: 65rem;
      width: auto;
      padding: 0 5rem;
      line-height: 12rem;
      &:before {
        top: 10%;
        height: 80%;
      }
    }

    .intro {
      width: 65rem;
      padding: 0 5rem;
      p {
        font-size: 1.6rem;
        line-height: 1.5;
      }
      &:before {
        top: 0;
        transform: translateY(-10%);
      }
    }

    .scroll-button {
      width: 3.75rem;
    }
  }
}
