.user-details {
  position: relative;
  z-index: 1;

  .background-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    .background-image-cover {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $light-blue;
      opacity: 0.3;
    }

    .bottom-gradient {
      z-index: 2;
      display: none;
    }
  }

  .graphics {
    svg {
      position: absolute;
      filter: $dropshadow;
      height: auto;
      z-index: 1;
    }
    .big-splash {
      left: 0;
      top: 5vw;
      width: 62vw;
    }

    .yellow-hollow-chip {
      width: 50vw;
      right: -16vw;
      transform: rotate(-5deg);
      top: 5vw;
      path {
        fill: $yellow;
      }
    }

    .red-hollow-chip-1 {
      width: 38vw;
      right: -15vw;
      top: 26vw;
      transform: rotate(-40deg);
      path {
        fill: $red;
      }
    }

    .red-hollow-chip-2 {
      left: -23vw;
      width: 50vw;
      top: 100vw;
      transform: rotate(-32deg);
      path {
        fill: $red;
      }
    }

    .mountains {
      top: 140vw;
      right: -5vw;
      width: 30vw;
    }
  }

  .content {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10vw;

    .avatar-container {
      width: 30vw;
      height: 30vw;
      margin-bottom: 5vw;
    }
    .user-info {
      > * {
        filter: $dropshadow;
      }
      .edit-profile-link {
        cursor: pointer;
        margin-bottom: 5vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
          color: $red;
          text-decoration: none;
          filter: drop-shadow(0 0 0.1rem $bright-white);
          svg {
            path {
              fill: $red;
            }
          }
        }
        svg {
          display: block;
          width: 10vw;
          height: 10vw;
          margin-bottom: 1vw;
        }
        span {
          font-family: $opensanscondensed;
          font-weight: 700;
          font-size: 5vw;
          text-transform: uppercase;
        }
      }

      h1 {
        font-size: 12vw;
        line-height: 1;
        max-width: 90vw;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      h2 {
        font-size: 6vw;
        line-height: 1;
        font-family: $opensanscondensed;
        font-weight: 700;
      }
    }

    .socials {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 80vw;
      margin: 5vw 0;
      a {
        display: block;
        margin: 1vw;

        svg,
        img {
          width: 20vw;
          height: 20vw;
        }
      }

      a:first-child:nth-last-child(n + 4),
      a:first-child:nth-last-child(n + 4) ~ a {
        svg,
        img {
          width: 15vw;
          height: 15vw;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .background-image-container {
      height: 35rem;
      top: 10rem;

      .background-image {
        left: 50%;
        top: 50%;
        height: auto;
        transform: translate(-50%, -50%);
      }

      .bottom-gradient {
        display: block;
        content: '';
        width: 100%;
        height: 40%;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        position: absolute;
        bottom: 0;
      }
    }
    .content {
      flex-direction: row;
      padding: 17.5rem 5rem 0;
      width: 100%;
      max-width: 150rem;
      z-index: 2;
      .avatar-container {
        width: 19.5rem;
        height: 19.5rem;
        margin-bottom: 0;
        padding: 0;
        margin-right: 3rem;
      }
      .user-info {
        text-align: left;

        .edit-profile-link {
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1rem;

          svg {
            width: 3rem;
            height: 3rem;
            margin: 0 1rem 0 0;
          }
          span {
            font-size: 2.25rem;
          }
        }

        h1 {
          font-size: 4rem;
        }
        h2 {
          font-size: 3rem;
          margin: 0;
        }
      }
      .socials {
        width: 30rem;
        margin: 0;
        position: absolute;
        right: 5rem;
        justify-content: flex-end;
        a {
          margin: 1rem;

          svg,
          img {
            width: 7.5rem;
            height: 7.5rem;
          }
        }
        a:first-child:nth-last-child(n + 4),
        a:first-child:nth-last-child(n + 4) ~ a {
          svg,
          img {
            width: 6.75rem;
            height: 6.75rem;
          }
        }

        a:first-child:nth-last-child(n + 6),
        a:first-child:nth-last-child(n + 6) ~ a {
          svg,
          img {
            width: 5.25rem;
            height: 5.25rem;
          }
        }
        a:first-child:nth-last-child(n + 9),
        a:first-child:nth-last-child(n + 9) ~ a {
          svg,
          img {
            width: 4rem;
            height: 4rem;
          }
        }
      }
    }
    .graphics {
      z-index: 1;

      .big-splash {
        width: 20vw;
        top: 0;
      }

      .red-hollow-chip-1 {
        width: 25vw;
        right: -5vw;
        top: 50rem;
      }

      .red-hollow-chip-2 {
        display: none;
      }

      .yellow-hollow-chip {
        width: 40vw;
        top: 60rem;
      }

      .mountains {
        bottom: -8vw;
        width: 14vw;
        top: auto;
      }
    }
  }

  // @include media-breakpoint-up(xl) {
  //   .content {
  //     .user-info {
  //       h1 {
  //         font-size: 8rem;
  //       }
  //       h2 {
  //         font-size: 4rem;
  //       }
  //     }
  //   }
  // }
}
