.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: $navHeightMobile;
  padding-bottom: 20vw;

  @include media-breakpoint-up(lg) {
    padding-top: $navHeightDesktop;
    padding-bottom: 9rem;
  }
}
