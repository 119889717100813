.tab-component {
  margin: 5vw 0;
  border-bottom: 1px solid $neon-green;
  padding-bottom: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .scrollable-container {
    ul {
      li {
        &.active {
          span {
            background-color: transparent;
            color: $neon-green;
          }
        }
      }
    }
  }

  > .scrollable-container {
    &.has-overflow {
      ul {
        li {
          &:first-of-type {
            span {
              padding-left: 3vw;
            }
          }
        }
      }
    }

    ul {
      li {
        &:first-of-type {
          span {
            padding-left: 0;
          }
        }
      }
    }
  }

  .tab-panel {
    display: none;
    align-self: flex-start;
    &.active {
      display: block;
    }

    ul,
    ol {
      padding: 0;
      li {
        font-size: 3.3vw;
        line-height: 1.5;
        margin-bottom: 1vw;
        span {
          padding: 0;
        }
      }
    }

    .scrollable-container {
      ul {
        li {
          &:first-of-type {
            span {
              padding-left: 0;
            }
          }
        }
      }
    }

    .tab-panel-content {
      ul {
        list-style: inside;
      }
      ol {
        list-style: decimal;
      }

      .submission-attachment {
        text-decoration: underline;
        &:hover {
          svg {
            fill: $red;
          }
        }
        svg {
          display: block;
          width: 2rem;
          background-size: 2rem 2rem;
          display: inline-block;
          fill: $white;
          height: 2rem;
          background-image: url('../../assets/svg/link-out-icon.svg');
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin: 2rem 0;
    padding-bottom: 1rem;

    .scrollable-container {
      ul {
        li {
          font-size: 1.5rem;
          margin: 0 1rem;
        }
      }
    }

    > .scrollable-container {
      &.has-overflow {
        ul {
          li {
            &:first-of-type {
              span {
                padding-left: 1rem;
              }
            }
          }
        }
      }
      ul,
      ol {
        li {
          font-size: 1.5rem;
          margin: 0 1rem;
        }
      }
    }

    .tab-panel {
      ul {
        li {
          font-size: 1.4rem;
          line-height: 1.5;
          margin: 0 0 1rem;
        }
      }

      p {
        margin-bottom: 1rem;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .tab-panel {
      ul,
      ol {
        li {
          font-size: 1.6rem;
        }
      }
    }
  }
}
